import { useContext } from "react";
import { GlobalContext } from "./index";

function App() {
  const context = useContext(GlobalContext);
  console.log(context);

  return <div className="text-5xl">App</div>;
}

export default App;
