import React, { useReducer } from "react";
import ReactDOM from "react-dom/client";
import { createContext } from "react";
import { RouterProvider } from "react-router-dom";
import { router } from "./Router";

export const GlobalContext = createContext({ currentUser: null });

const actions = {
  LOGIN: "LOGIN",
};

const initialGlobalState = {
  currentUser: null,
};

const globalReducer = function (state, action) {
  switch (action.type) {
    case actions.LOGIN:
      return { ...state, currentUser: "jonnyboy" };
    default:
      return state;
  }
};

export const GlobalContextProvider = function ({ children }) {
  const [state, dispatch] = useReducer(globalReducer, initialGlobalState);

  const contextValue = {
    state,
    loginAction: (username, password) =>
      console.log("Hello I am trying to login"),
  };

  return (
    <GlobalContext.Provider value={contextValue}>
      {children}
    </GlobalContext.Provider>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <GlobalContextProvider>
      <RouterProvider router={router}></RouterProvider>
    </GlobalContextProvider>
  </React.StrictMode>
);
