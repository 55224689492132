import "./Login.css";

const { Fragment } = require("react");

function App() {
  return (
    <Fragment>
      <div class="sct brand">
        <img id="logo-image" width="400px" src="logo_ct5.png" />
      </div>
      <div class="sct login">
        <form>
          <h3>Login</h3>
          <input type="email" name="email" placeholder="Email" />
          <input type="password" name="password" placeholder="Password" />
          <div class="forgot-remember">
            <div class="forgot">
              <a href="#">Forgot Password?</a>
            </div>
          </div>
          <input type="submit" name="send" value="Entrar" />
          <div class="social-sign">
            <a href="#">
              <i class="fa fa-facebook-square" aria-hidden="true"></i>
            </a>
            <a href="#">
              <i class="fa fa-twitter-square" aria-hidden="true"></i>
            </a>
            <a href="#">
              <i class="fa fa-linkedin-square" aria-hidden="true"></i>
            </a>
          </div>
        </form>
      </div>
    </Fragment>
  );
}

export default App;
